// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom'

// Components 
import UserCards from '../Users/UserCards'

class ProgramsMentors extends Component{
    render(){
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 DescriptionHeaderTitle my-4">
                        <h5>Associated Mentors with this program</h5>
                    </div>

                   

                </div>

                <UserCards
                    UserName="Antonio"
                    UserOccupation="CEO"
                    UserWorkplace="MentorMe"
                    UserAverage={5.0}
                />

<UserCards
                    UserName="Antonio"
                    UserOccupation="CEO"
                    UserWorkplace="MentorMe"
                    UserAverage={5.0}
                />

<UserCards
                    UserName="Antonio"
                    UserOccupation="CEO"
                    UserWorkplace="MentorMe"
                    UserAverage={5.0}
                />

<UserCards
                    UserName="Antonio"
                    UserOccupation="CEO"
                    UserWorkplace="MentorMe"
                    UserAverage={5.0}
                />

<UserCards
                    UserName="Antonio"
                    UserOccupation="CEO"
                    UserWorkplace="MentorMe"
                    UserAverage={5.0}
                />

            </div>

           
            
        )
    }
}

export default ProgramsMentors;