// Dependecies
import React,{Component} from "react";
import {Link} from 'react-router-dom'

// Components
import Document from './Document';


class Session extends Component{
    render(){
        return(
            <div className="text-left my-4">
                <div className="row">
                    <div className="col-8 SessionTitle">
                        <h4>{this.props.SessionTitle}</h4>
                    </div>
                    <div className="col-4 text-right">
                        <Link className="AddNew pr-2" to={this.props.AddNewLink}><i className="fas fa-plus-circle"></i><span className="AddText">&nbsp;&nbsp;Add New</span></Link>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <Document/>
                    <Document/>
                    <Document/>
                    <Document/>
                    <Document/>
                    <Document/>
                    <Document/>
                    <Document/>
                </div>
            </div>
        )
    }
}

export default Session;