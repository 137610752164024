// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom'

// Components 
import UserCards from '../Users/UserCards'

class ProgramsMentees extends Component{
    render(){
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 DescriptionHeaderTitle my-4">
                        <h5>Associated Mentees with this Program</h5>
                    </div>

                </div>

                

                <UserCards
                    UserName="Juan"
                    UserOccupation="Designer"
                    UserWorkplace="MentorMe"
                    UserAverage={4.5}
                /> 
 <UserCards
                    UserName="Juan"
                    UserOccupation="Designer"
                    UserWorkplace="MentorMe"
                    UserAverage={4.5}
                /> 
 <UserCards
                    UserName="Juan"
                    UserOccupation="Designer"
                    UserWorkplace="MentorMe"
                    UserAverage={4.5}
                /> 
 <UserCards
                    UserName="Juan"
                    UserOccupation="Designer"
                    UserWorkplace="MentorMe"
                    UserAverage={4.5}
                /> 
 <UserCards
                    UserName="Juan"
                    UserOccupation="Designer"
                    UserWorkplace="MentorMe"
                    UserAverage={4.5}
                /> 


            </div>

           
            
        )
    }
}

export default ProgramsMentees;