// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// Components
import AdminCard from './AdminCard';
import Interests from '../Users/Interests';

// Assets
import imgEx from '../../imgEx.jpg';
import UserCards from '../Users/UserCards';



class InstitutionProfile extends Component{

    constructor(props){
        const institutionsInfo = JSON.parse(localStorage.getItem('Institutions'));
        console.log(institutionsInfo)
        const institution = institutionsInfo.find(function(value,index){return index==0});
        console.log(institution);
        super(props);
        this.state = {
          }
      }
    render(){

        const Print = () =>{
            return this.props.admins.map((result,index,array) =>
              <AdminCard
                email={result.mail}
                confirmationDate={result.confirmationDate}
                adminStatus={result.active}
                institutionId={this.props._id}
              />);
          }

          const PrintInterests = () => {
              return this.props.professionalInterest.map((result,index,array) =>
                <Interests
                    interests={result.interests}
                    category={result.category}
                />
              ); 
          } 
       

        return(
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-8"></div>
                    <div className="col-4 float-right text-right">
                        {/*<button className="btn btn-transparent mr-2">Delete</button>*/} 
                        <button className="btn btn-primary">Edit</button>
                    </div>
               </div>   
                <div className="row mt-3">
                       <div className="col-2"><img src={imgEx} alt="" className="InstProfileImg"/></div>
                       <div className="col-10 p-0">
                           <div className="row p-0">
                                <div className="col-7 text-left h4 pt-2">{this.props.institutionName}</div>
                           </div>
                           <div className="row pt-3">
                                <div className="col-3 p-0">  
                                        <div className="row">
                                            <div className="col-2 text-right p-0">
                                                <i class="fas fa-map-marked-alt InstProfileInfoHeader"></i>
                                            </div>
                                            <div className="col-10 text-left">
                                                <div className="row"><span className="InstProfileInfoHeader">ADDRESS</span></div>
                                                <div className="row"><p className="InstProfileInfoText">{this.props.streetAddress + ". " +this.props.city + ", " + this.props.state + ", " + this.props.country}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0">  
                                        <div className="row">
                                            <div className="col-2 text-right p-0">
                                                <i class="fas fa-map-marked-alt InstProfileInfoHeader"></i>
                                            </div>
                                            <div className="col-10 text-left">
                                                <div className="row"><span className="InstProfileInfoHeader">EMAIL</span></div>
                                                <div className="row"><p className="InstProfileInfoText">{this.props.email}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0">  
                                        <div className="row">
                                            <div className="col-2 text-right p-0">
                                                <i class="fas fa-map-marked-alt InstProfileInfoHeader"></i>
                                            </div>
                                            <div className="col-10 text-left">
                                                <div className="row"><span className="InstProfileInfoHeader">PHONE</span></div>
                                                <div className="row"><p className="InstProfileInfoText">{this.props.phone}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0">  
                                        <div className="row">
                                            <div className="col-2 text-right p-0">
                                                <i class="fas fa-map-marked-alt InstProfileInfoHeader"></i>
                                            </div>
                                            <div className="col-10 text-left">
                                                <div className="row"><span className="InstProfileInfoHeader">WEBSITE</span></div>
                                                <div className="row"><p className="InstProfileInfoText">{this.props.webPageUrl}</p></div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                       </div>
                    </div>

                <div className="row AboutInstitution text-left px-4">
                    <div className="col-12">
                        <h5 className="mt-3 mb-0">About the institution</h5>
                    </div>
                    <div className="col-12">
                        <p className="my-3">{this.props.description}</p>
                    </div>
                </div>

                <div className="row Interests text-left px-4">
                    <div className="col-6">
                        <h5>Profesional Interests</h5>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="AddNew pr-2"  to={"/institutions/details/newinterests/" + this.props.institutionName + "/" + this.props._id}><i className="fas fa-plus-circle"></i><span className="AddText">&nbsp;&nbsp;Add New Interests</span></Link>
                    </div>
                    <div className="col-12 profesionalInterests">
                        <PrintInterests/>
                    </div>
                </div>

                <div className="row InstitutionalsAdmins px-4">
                    <div className="col-6 text-left">
                        <h5>Insitutional Admins</h5>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="AddNew pr-2"  to={"/institutions/details/newadmin/" + this.props.institutionName + "/" + this.props._id}><i className="fas fa-plus-circle"></i><span className="AddText">&nbsp;&nbsp;Add New Admin</span></Link>
                    </div>

                    <div>
                        
                    </div>
                    <Print/>
                </div>
            </div>
        )
    }
}

export default InstitutionProfile;