// Dependencies
import React,{Component} from 'react';


// Components 
import ProgramsCards from '../Programs/ProgramsCards'

// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;

class InstitutionalPrograms extends Component{

    constructor(props){
        super(props);
        this.state = {
            }
    }


    render(){
        
        const ProgramInfo = JSON.parse(localStorage.getItem('InstitutionsPrograms'));

        const Print = () =>{
           return ProgramInfo.map((result,index,array) =>
            <ProgramsCards
                name={result.name}
                institutionName={result.institutionName} 
                startDate={result.startDate.substring(0,10)} 
                endDate={result.endDate.substring(0,10)}
            />
          )
          }
        

        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-right mt-2">
                        <a className="AddNew pr-2" replace href={"/programs/addnew/" + this.props.institutionName + "/" + this.props._id} ><i className="fas fa-plus-circle"></i><span className="AddText">&nbsp;&nbsp;Add New Program</span></a>
                    </div>
                </div>

                <Print/>
            </div>

           
            
        )
    }
}

export default InstitutionalPrograms;