// Dependencies
import React, {Component} from 'react';

class ProgramsCards extends Component{

    render(){
        return(
        <div className="ProgramsCards"> 
                <div className="ProgramsRows row ">

                    <div className="col-3 ProgramsCols text-left">
                        <div className="row ProgramCardHeaders">
                            <div>Program name:</div>
                        </div>
                        <div className="row pt-1">
                            {this.props.name}
                        </div>
                    </div>

                    <div className="col-3 InfoProgramCol">
                        <div className="row ProgramCardHeaders">
                            <div>Insitution</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.institutionName}
                        </div>
                    </div>


                    <div className="col-2">
                        <div className="row ProgramCardHeaders">
                            <div>Start Date</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.startDate}
                        </div>
                    </div>

                    <div className="col-2">
                        <div className="row ProgramCardHeaders">
                            <div>End Date</div>
                        </div>
                        <div className="row ProgramCardInfo">
                            {this.props.endDate}
                        </div>
                    </div>

                </div>
            <hr/>
        </div>
        )
    }

}

export default ProgramsCards;