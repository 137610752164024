// Dependencies
import React from 'react';
import {Route,BrowserRouter as Router,Switch } from 'react-router-dom';

// Assets
import './App.css';

// Routes
import AppRoutes from './routes'

// Components
import Login from './components/Login/Login.js'
import Confirmation from './components/Login/Confirmation.js'
import ResetPassword from './components/Login/ResetPassword';


// Server URL
import serverURL from './serverURL'
var direccion =  serverURL ;



function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route path="/reset" component={ResetPassword}/>
        <Route path="/acceptAdminInvite" component={Confirmation}/>
        <Router>
          <AppRoutes/>
        </Router>  
      </Switch>        
    </div>
  );
}

export default App;
