// Dependecies
import React,{Component} from "react";

// Components 
import RolesResponsabilities from './RolesResponsabilities'

// Assets
import Captura from '../../Captura.png';

class ProgramsSummary extends Component{

    constructor(props){
        super(props);
      }

    render(){
        const Print = () =>{
            return this.props.responsabilities.map((result,index,array) =>
              <RolesResponsabilities
                    createdOn={result.createdOn}
                    isMenteeOwner={result.isMenteeOwner}
                    isMentorOwner={result.isMentorOwner}
                    title={result.title}
              />);
          }

        return(
            <div className="container-fluid text-left summary">
                <div className="row py-4">
                    <div className="col-8"><h5><strong>{this.props.name}</strong></h5></div>
                    <div className="col-4 float-right text-right">
                        <button className="btn btn-transparent mr-2">Delete</button>
                        <button className="btn btn-primary">Update</button>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-2">
                        <div className="row">
                            <strong>Start Date:</strong>
                        </div>
                        <div className="row">
                            {this.props.startDate.substring(0,10)}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="row">
                            <strong>End Date:</strong>
                        </div>
                        <div className="row">
                            {this.props.endDate.substring(0,10)}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="row">
                            <strong>Actual Matches:</strong>
                        </div>
                        <div className="row">
                            0
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="row">
                            <strong>Mentors:</strong>
                        </div>
                        <div className="row">
                            0
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="row">
                            <strong>Mentees:</strong>
                        </div>
                        <div className="row">
                            0
                        </div>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-12"><strong>Institution</strong></div>
                    <div className="col-12">
                       {this.props.institutionName}
                    </div>
                </div>

                <div className="row py-2">
                    <div className="row">
                        <div className="col-12"><strong>Description</strong></div>
                        <div className="col-12">
                            <p>
                                {this.props.description}
                            </p>
                        </div>
                    </div>
                    </div>
                <hr/>
                <div className="row roles">
                    <div className="col-12 text-left  py-2">   
                        <div className="row">
                            <div className="col-5"><strong>Responsabilities</strong></div>
                            <div className="col-3">Date</div>
                            <div className="col-2 text-center"><strong>Mentor</strong></div>
                            <div className="col-2 text-center"><strong>Mentee</strong></div>
                        </div>
                        <hr/>
                    </div>

                    <Print/>

                </div>
                
            </div>
        )
    }
}

export default ProgramsSummary;