// Dependencies
import React, {Component} from "react";
import axios from "axios";

// Components
import SearchBar from '../Bars/SearchBar.js'
import InstitutionsCards from './InstitutionsCards.js'

// CSS
import './Institutions.css';

// Server URL
import serverURL from '../../serverURL'
var direccion = serverURL;

export class Institutions extends React.Component {

  constructor(props) {
    super(props);
    var userRole = JSON.parse(localStorage.getItem('User'));
    this.state = {
      currentInstitution: [],
      institutions: JSON.parse(localStorage.getItem('Institutions')),
      institutionsPrograms: [],
      userRole: [userRole.role]
    }

  }

  selectedCard(institution) {
    localStorage.setItem("selectedInstitution", JSON.stringify(institution));
    axios
      .get(direccion + "api/institution/program/" + institution._id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("UserSession")
      }
    })
      .then((response) => {
        localStorage.setItem("InstitutionsPrograms", JSON.stringify(response.data.programs));
        window.open("/institutions/details/profile", "_self")
      })
      .catch((error) => {
        console.log(error.response);
      });
  }


  render() {

    const Print = () => {
      if (this.state.userRole == "sysadmin") {
        var local = localStorage.getItem("selectedInstitution");
        this.state.currentInstitution = [local]
        return (
          <div>
            <SearchBar AddNewLink="/institutions/addnew" SearchType="institution" hide=" d-md-block"/>
          </div>

        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-7">
                <button
                  className="btn btn-outline-primary btn-sm p-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="true"
                  aria-controls="multiCollapseExample1">Show institutions</button>
              </div>
            </div>
            {this
              .state
              .institutions
              .map((result, index, array) => <div
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="show multi-collapse">
                <InstitutionsCards
                  InstitutionName={result.institutionName}
                  InstitutionId={result._id}
                  programs={result.programs}/>
              </div>)}
          </div>
        )
      }
    }

    return (

      <div>

        <Print/>

      </div>
    )

  }

}

export default Institutions;
