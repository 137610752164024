// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom'


class UserPerformance extends Component{
    render(){
        return(
            <div></div>
        )
    }
}

export default UserPerformance;