// Dependencies
import React, {Component} from "react";
import PropTypes from 'prop-types';

// Routes
import AppRoutes from '../routes'

// Components
import TopBar from './NavBar/TopBar'
import Content from './Content'
import SideBar from './NavBar/SideBar.js'; 

// Data
import menuV from './NavBar/SideBarData' 
import menuV2 from './NavBar/SideBarData2' 

class Main extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  dynamicMenu(){
    var user;
    var userRole;
    if("UserSession" in localStorage){
      user = JSON.parse(localStorage.getItem('User'));
      var userRole = user.role;
      if(userRole=="user"){
        return  menuV;
      }else{
        return menuV2;
      }
    }
  }



  render() {
    
    
    var user;
    var usertoken;
    var userName;

      if("UserSession" in localStorage){
         user = JSON.parse(localStorage.getItem('User'));
         usertoken = localStorage.getItem('UserSession');
        userName = user.userName; 
      }

    const {children} = this.props;
   

    return (
      
      <div className="">
        <SideBar 
          items={this.dynamicMenu()}
        />

        <div id="MainContent">
          <TopBar
            UserName= {userName}
          />
          <Content body={children}/>
        </div>
        
      </div>
    )

  }

}

export default Main;
