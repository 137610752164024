import React from "react";
import { Bar } from "react-chartjs-2";

class BarCharts2 extends React.Component {
  state = {
    dataBar: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      datasets: [
        {
          label: "Mentors",
          data: [20, 12, 4, 15, 12, 10],
          backgroundColor: [
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)"
          ],
          borderWidth: 2,
          borderColor: [
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)",
            "rgb(60,40,101)"
          ]
        }
      ]
    },
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    }
  }


  render() {
    return (
        <div className="chart-card">
            <h4 className="chart-header">Mentees without match</h4>
            <Bar data={this.state.dataBar} options={this.state.barChartOptions} />
        </div>
    );
  }
}

export default BarCharts2;