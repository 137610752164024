// Dependencies
import React,{Component} from 'react';
import { Link } from "react-router-dom";

// Components 
import UserCards from '../Users/UserCards'

class InstitutionalUsers extends Component{
    constructor(props) {
        super(props);
        this.state = {
          users: JSON.parse(localStorage.getItem("InstitutionsUsers"))
        }
      }

    render(){

        const Print = () =>{
            return this.state.users.map((result,index,array) =>
              <UserCards
                ProgramName= {result}
              />);
          }

        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 DescriptionHeaderTitle my-3">
                        <h5>Associated Users to this institution</h5>
                    </div>
                    <div className="col-md-2 text-right my-3">
                        <Link className="AddNew pr-2" to="/institutions/details/newusers">
                            <i className="fas fa-plus-circle"></i>
                            <span className="AddText">&nbsp;&nbsp;Add new user/s</span>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <Print/>
                </div>
            </div>

           
            
        )
    }
}

export default InstitutionalUsers;