import React from "react";
import { Pie } from "react-chartjs-2";

class PieCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPie: {
        labels: this.props.headerNames,
        datasets: [
          {
            data: this.props.counts,
            backgroundColor: [
              "#006BA4",
              "#004a72",
              "#3288b6",
              "#66a6c8",
              "#99c3da",
              "#003552"
            ],
            hoverBackgroundColor: [
              "#006BA4EE",
              "#004a72EE",
              "#3288b6EE",
              "#66a6c8EE",
              "#99c3daEE",
              "#003552EE"
            ]
          }
        ]
      }
    }
  }

  calcularTotal(){
    var a = this.props.counts[0]
    var b = this.props.counts[1]
    return a+b;
  }

  render() {
    return (
        <div className="chart-card">
            <h4 className="chart-header">{this.props.title}</h4>
            <Pie data={this.state.dataPie} options={{ responsive: true }} />
            <h6 className="my-3">Total:{" " + this.calcularTotal()}</h6>
        </div>
    );
  }
}

export default PieCharts;