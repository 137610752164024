// Dependencies
import React, {Component} from "react";

// Components
import DashboardCards from './DashboardCards.js'
import LineCharts from '../Charts/LineCharts.js';
import BarCharts from '../Charts/BarCharts.js';
import BarCharts2 from '../Charts/BarCharts2.js';
import PieCharts from '../Charts/PieCharts.js';
import Axios from "axios";
import Swal from 'sweetalert2'

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    //var data = JSON.parse(localStorage.getItem('Data'));
    //var count = data.dashboard;
    this.state = {
      //programsCount: count.programs,
      //matchesCount: count.matches
      matchesData: JSON.parse(localStorage.getItem("matchesData")),
      lastActivities: JSON.parse(localStorage.getItem("lastActivities")),
      programsCount: JSON.parse(localStorage.getItem("programsCount")),
      programsGoals: JSON.parse(localStorage.getItem("programsGoals"))
      
    }

  }

  componentDidMount(){
    Axios.post("http://api-itg.mentormeconnect.com/legacy/getAdminMetrics",{userId:"200"}
    )
    .then((response) => {
        console.log(response);
        localStorage.setItem("matchesData",JSON.stringify(response.data.legacy.matches));
        localStorage.setItem("lastActivities",JSON.stringify(response.data.legacy.lastActivities));
        localStorage.setItem("programsCount",JSON.stringify(response.data.legacy.programs.count));
        localStorage.setItem("programsGoals",JSON.stringify(response.data.legacy.programs.goals));
    } )
      .catch((error)=> {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              text: error
            }).then((result) => {

            })
          console.log(error.response);;
          }); 
    
      }  

      renderTableDataMatches() {
        return this.state.matchesData.map((match, index) => {
           const { menteeMail, mentorMail,status, request_time,acepted_date_time} = match //destructuring
           var rT = new Date(request_time).toLocaleString() ;
           if(acepted_date_time == null){
             var aD = "-"
           }else{
            var aD = new Date(acepted_date_time).toLocaleString();
           }
           return (
              <tr key={ index }>
                 <td>{menteeMail}</td>
                 <td>{mentorMail}</td>
                 <td>{status}</td>
                 <td>{ rT }</td>
                 <td>{ aD }</td>
              </tr>
           )
        })
     }

     
     renderTableDataActivities() {
      return this.state.lastActivities.map((activie, index) => {
         const { email, last_name,first_name, registered_on,role,last_activity_on,last_activity_type} = activie //destructuring
         var rO = new Date(registered_on).toLocaleString();
         var lA = new Date(last_activity_on).toLocaleString();
         return (
            <tr key={ index }>
               <td>{email}</td>
               <td>{last_name}</td>
                <td>{first_name}</td>
               <td>{ rO }</td>
               <td>{role}</td>
               <td>{ lA }</td>
                <td>{  last_activity_type}</td>
            </tr>
         )
      })
   }
  

  render() {

    return (
        <div>
          {/*<div className="row DashboardCards border-between border-between2">
              <DashboardCards
                CardTitle="Programs"
                CardNumber={/*this.state.programsCount2}
                TagCard="/programs"
              />
              <DashboardCards
                CardTitle="Matches"
                CardNumber={/*this.state.matchesCount2}
                TagCard="/institutions"
              />
          </div>*/}
          <div className="container-fluid">
                <div className="row">
                    {/*<div className="col-4 col-lg-6  chart-box">
                        <LineCharts/>
                    </div>
                    <div className="col-4 col-lg-6 chart-box">
                        <BarCharts/>
                    </div>
                    <div className="col-4 col-lg-6 chart-box">
                        <BarCharts2/>
                    </div>*/}
                   
                    <div className="col-12">
                      <div className="metric-card my-2">
                        <span className="h2">Matches</span>
                        <div className="table-responsive">
                          <table class="table table-bordered" id="matches" width="100%" cellspacing="0">
                            <thead>
                              <tr>
                                <th>Mentee mail</th>
                                <th>Mentor Mail</th>
                                <th>Status</th>
                                <th>Request date</th>
                                <th>Accepted date</th>
                              </tr>
                            </thead>
                              <tbody>
                                {this.renderTableDataMatches()}
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="metric-card my-2">
                        <span className="h2">Activities</span>
                        <div className="table-responsive">
                          <table class="table table-bordered" id="activities" width="100%" cellspacing="0">
                            <thead>
                              <tr>
                                <th>Email</th>
                                <th>Last name</th>
                                <th>First name</th>
                                <th>Registered on</th>
                                <th>Role</th>
                                <th>Last activity</th>
                                <th>Activity type</th>
                              </tr>
                            </thead>
                              <tbody>
                                {this.renderTableDataActivities()}
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  <div className="col-6 chart-box px-5">
                        <PieCharts
                            headerNames={[this.state.programsCount[0].goal_status,this.state.programsCount[1].goal_status]}
                            counts={[this.state.programsCount[0].count,this.state.programsCount[1].count]}
                            title={"Goals summary"}
                        />
                    </div>

                </div>
            </div>
        </div>
    )

  }

}

export default Dashboard;
