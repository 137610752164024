// Dependencies
import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios"; 

// CSS
import './SearchBar.css';

// Components
import UserCards from '../Users/UserCards';
import InstitutionsCards from '../Institutions/InstitutionsCards';
import ProgramCards from '../Programs/ProgramsCards'

// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;

class SearchResults extends Component {

   
constructor(props){
  super(props);
}
    

  render() {
    let cards;
    const cardType = this.props.cardType;
    const searchResults = this.props.searchResults;
    const hola = this.props.hola;
    console.log(searchResults,hola)

    if (cardType == "UserCards"){
      cards = <UserCards/>;
    }else if(cardType == "InstitutionsCards"){
      cards = <InstitutionsCards/>;
    }else if(cardType == "ProgramsCards"){
      cards = <ProgramCards/>;
    }

    return (
      <div className="container-fluid">
       {cards}
      </div>
    )

  }
}


export default SearchResults;
