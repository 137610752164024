// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// Assets

// Components




class Modal extends Component{
    
    render(){ 
    var modal = document.getElementById("exampleModal"); 
    var btn = document.getElementById("AddInstitution");
    var close = document.getElementsById("CloseModal");

        return(
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  ...
                </div>
                <div class="modal-footer">
                  <button id="CloseModal" type="button" class="btn btn-secondary" >Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
        )
    }

}

export default Modal;