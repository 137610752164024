// Dependencies
import React,{Component} from 'react';
import Ratings from 'react-ratings-declarative';
import {Link} from 'react-router-dom';

// Components
import Interests from './Interests';
import PropTypes from 'prop-types';

// Assets
import imgEx2 from '../../imgEx2.jpg';    

class UserCards extends Component{
    static propTypes= {
        StarsNumber: PropTypes.number
      }
    

    render(){
        var  StarsNumber=this.props.UserAverage;
        console.log(StarsNumber);
        
        return(
            
            <div className="Users">
                <Link to="/users/details">
                    <div className="UsersRows row ">

                        <div className="col-2 UsersCols text-center">
                            <img src={imgEx2} className="UserCardImg"/>
                        </div>

                        <div className="col-3 InfoUserCol text-left">
                            <div className="col-12  UserInfoHeader Raleway text-left">Name</div>
                            <div className=" col-12 UserName Raleway"><strong>{this.props.UserName}</strong></div>
                        </div>

                        <div className="col-5">
                            <div className="row">
                                <div className="col-12  UserInfoHeader Raleway text-left">Email</div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-center pt-1">{this.props.email}</div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <div className="col-12  UserInfoHeader Raleway text-left">Role</div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-center pt-1">{this.props.role}</div>
                            </div>
                        </div>


                       {/* <div className="col-3 text-left">
                            <div className="row">
                                <div className="col-12 UserInfoHeader Raleway">Time without match</div>
                            </div>
                            <div className="row ">
                                <div className="col-12 text-left pr-0 mt-3">
                                        10 days
                                </div>
                            </div>
                        </div>*/}

                    </div>
                </Link>
                <hr/>
            </div>

        )
    }
}

export default UserCards;   