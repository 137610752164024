// Dependecies
import React,{Component} from "react";

// Components
import Task from './Tasks'

class Goals extends Component{
    render(){
        return(
            <div className="row goal">
                <div className="col-12">
                    <div className="row text-left pt-3">
                        <div className="col-6 goal-header">Goal</div>
                        <div className="col-3 goal-header">Start Date</div>
                        <div className="col-3 goal-header">Duration</div>
                    </div>
                    <div className="row text-left py-3">
                        <div className="col-6">{this.props.description}</div>
                        <div className="col-3">{this.props.startDate.toString().substring(0,10)}</div>
                        <div className="col-3">{this.props.durationInDays}</div>
                    </div>
                </div>
                <div className="col-12 Task">
                    <div className="row py-3">
                        <div className="col-12 text-center" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><strong>Show task list&nbsp;&nbsp;<i class="fas fa-chevron-down"></i></strong></div>
                    </div>
                    <div className="py-3 collapse" id="collapseExample">
                        <div className="col-12">
                            <div className="row  TaskHeader">
                                <div className="col-6 text-left">Task</div>
                                <div className="col-2 text-center">Duration</div>
                                <div className="col-2  text-center">Mentor</div>
                                <div className="col-2  text-center">Mentee</div>
                            </div>
                            <hr/>
                        </div>
                        {this.props.tasks.map((v,i,a) => 
                                {return  <Task
                                        description= {v.description}
                                        durationInDays= {v.durationInDays}
                                        endDate= {v.endDate}
                                        menteeAssignment= {v.menteeAssignment}
                                        mentorAssignment= {v.mentorAssignment}
                                        startDate = {v.startDate}
                                        targetDate = {v.targetDate}
                                        targetDateStart= {v.targetDate}
                                    />
                                } 
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Goals;