// Dependencies
import React, {Component} from "react";
import {Link} from 'react-router-dom';

// CSS
import './DashboardCards.css'

export class DashboardCards extends React.Component {


  render() {
    return (
      <div className="col-6 DashboardCard">
        <Link to={this.props.TagCard}>
          <div className="DashboardCardTitle align-middle Raleway">
            {this.props.CardTitle}
          </div>
          <div className="DashboardCardNumber align-middle">
            {this.props.CardNumber}
          </div>
        </Link>
      </div>
  )

}

}

export default DashboardCards;
