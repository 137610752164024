// Dependencies
import React, {Component} from "react";
import axios from "axios"; 

// Assets
import logo from '../../logo.png';

// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;

class ResetPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:''
    }
  }

  hadleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState( { [name]:value } );
  }




  render() {
    
    const Email = this.props.location.pathname.split("/").slice(1)[1];
    const Token = this.props.location.pathname.split("/").slice(1)[2];
    const userExists = this.props.location.pathname.split("/").slice(1)[3];
    console.log(Email,Token,userExists);

    return (
      <div className="Container loginBG">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className="row">
          <div className="col-1 col-md-4"></div>
          <div className="col-10 col-md-4">
            <div className="card login-card">
              <div className="card-body">
                <div className="card-title gray">Reset Password</div>
                  <div className="container">
                    <form>
                      <div className="row">
                        <input disabled type="email" id="inputEmail" placeholder="User:" className="form-input" name="email" value={Email} onChange={(event) => this.hadleUserInput(event)} />
                      </div>
                      <div className="row">
                        <input type="password" id="password" placeholder="New password" className="form-input" name="password" value={this.state.password}  onChange = {(event) => this.hadleUserInput(event)}/>
                      </div>
                      <div className="row">
                        <input type="password" id="passwordConfirm" placeholder="Confirm password" className="form-input" name="passwordConfirm" value={this.state.passwordConfirm}  onChange = {(event) => this.hadleUserInput(event)}/>
                      </div>
                      <div id="testing">
                        <p>

                        </p>
                      </div>
                      <button 

                        onClick={ () => {
                                var password = document.getElementById("password").value;
                                var passwordConfirm = document.getElementById("passwordConfirm").value;
                                
                                if (password === passwordConfirm ) {
                                  axios.post(direccion + "access/confirmAdminInvite/"  + Email + "/" + Token + "/" + userExists,{password : this.state.password})
                          
                                  .then((response) => {
                                      console.log(response.data); 
                                      
                                  } )
        
                                  .catch((error)=> {
                                      console.log(error.response.data);
                                      alert("LOGIN INCORRECTO");
                                      document.getElementById("testing").innerHTML = JSON.stringify(error.response.data);
                                      }); 
                                
                                    
                                } else {
                                    alert("Contraseña no coinciden");
                                }
                          
                            }}  

                        type="button" className="btn btn-primary loginButtons">
                         Accept
                      </button>

                    </form>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-4"></div>
        </div>
      </div>
    )

  }

}

export default ResetPassword;
