// Dependencies 
import React,{Component} from 'react';
import {NavLink, Switch, Route,BrowserRouter} from 'react-router-dom';

// Components
import LineCharts from '../Charts/LineCharts.js';
import BarCharts from '../Charts/BarCharts.js';
import BarCharts2 from '../Charts/BarCharts2.js';
import PieCharts from '../Charts/PieCharts.js';

// CSS
import './Analytics.css'

class Analytics extends Component{
    render(){
        return(
            <div className="container-fluid">
                <BrowserRouter>
                    <div className="SubMenu">
                        <ul className="row">
                            <NavLink to="/programs/details/summary"  className="col-2"><li>Overall</li></NavLink>
                            <NavLink to="/programs/details/goalstasks"  className="col-2"><li>Institutions</li></NavLink>
                            <NavLink to="/programs/details/documents"  className="col-2"><li>Programs</li></NavLink>
                            <NavLink to="/programs/details/documents"  className="col-2"><li>Matches</li></NavLink>
                            <NavLink to="/programs/details/mentors"  className="col-2"><li>Mentees</li></NavLink>
                            <NavLink to="/programs/details/mentors"  className="col-2"><li>Mentors</li></NavLink>
                        </ul>
                    </div>

                    <Switch>
                        <Route path="/programs/details/summary" component={Analytics}/>
                        <Route path="/programs/details/goalstasks" component={Analytics}/>
                        <Route path="/programs/details/documents" component={Analytics}/>
                        <Route path="/programs/details/mentors" component={Analytics}/>
                        <Route path="/programs/details/mentees" component={Analytics}/>
                    </Switch>
                </BrowserRouter>

                <div className="row">
                    <div className="col-4 col-lg-6  chart-box">
                        <LineCharts/>
                    </div>
                    <div className="col-4 col-lg-6 chart-box">
                        <BarCharts/>
                    </div>
                    <div className="col-4 col-lg-6 chart-box">
                        <BarCharts2/>
                    </div>
                    <div className="col-4 col-lg-6 chart-box">
                        <PieCharts/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Analytics;