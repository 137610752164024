// Dependecies
import React,{Component} from "react";

// Components
import Goal from './Goals'

class ProgramsGoalsTasks extends Component{
    render(){

        var Print = () => {
            return this.props.goals.map((v,i,a) => 
                <Goal
                    description = {v.description}
                    documents = {v.documents}
                    durationInDays = {v.durationInDays}
                    links = {v.links}
                    startDate = {v.startDate}
                    subject = {v.subject}
                    tasks = {v.tasks}
                />
            )
        }


        return(
            <div className="p-4">
                <Print/>
            </div>
        )
    }
}

export default ProgramsGoalsTasks;