// Dependencies
import React,{Component} from 'react';
import axios from "axios"; 
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;

class AddProgram extends Component{
   

    constructor(props){
        super(props);
        this.state = {
            startDate:'',
            endDate:'',
            programName:'',
            programDesc:'',
            responsabilities:[{title:'',isMenteeOwner:false,isMentorOwner:false}],
            links:[{title:'',address:'',createdBy:localStorage.getItem("UserId"),createdOn:''}]
        }
      }
    
      hadleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState( { [name]:value } );
      }

     changeResponsabilitie(e,index,currentValue){
          console.log(currentValue);
          this.state.responsabilities[index][e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
          this.setState({responsabilities:this.state.responsabilities});
          console.log(this.state.responsabilities);
      }
    
    changeLink(e,index,currentValue){
        console.log(currentValue);
        this.state.links[index][e.target.name] = e.target.value;
        this.setState({links:this.state.links});
        console.log(this.state.links);
    }
    

      addResponsabilitie(e){
        e.preventDefault() 
        this.setState({responsabilities:[...this.state.responsabilities,{title:'',isMenteeOwner:false,isMentorOwner:false}]});
        console.log(this.state.responsabilities);
      }

      addLink(e){
        e.preventDefault() 
        this.setState({links:[...this.state.links,{title:'',address:'',createdBy:localStorage.getItem("UserId"),createdOn:''}]});
        console.log(this.state.links);
      }

      onDelete(e,index,currentValue) {
        e.preventDefault() 
        this.state.responsabilities.splice([index],1);
        this.setState({responsabilities:this.state.responsabilities});
      };



    render(){
        
        return(
            <div className="container my-3">
                <form action="">
                    <div className="row text-left">
                        <div className="col-6  mt-4">
                            Program Name
                            <input type="text" className="mt-2" name="programName" value={this.state.programName}  onChange={(event) => this.hadleUserInput(event)} required/>
                        </div>
                        <div className="col-3 mt-4">
                            Start date<br/>
                            <input type="date" className="mt-2 w-100" name="startDate" value={this.state.startDate}  onChange={(event) => this.hadleUserInput(event)} required/>
                        </div>
                        <div className="col-3 mt-4 mb-2">
                            End date<br/>
                            <input type="date" className="mt-2 w-100" name="endDate" value={this.state.endDate}  onChange={(event) => this.hadleUserInput(event)} required/>
                        </div> 
                        <div className="col-12 mt-4">
                            Program description
                            <input type="text" className="mt-2" name="programDesc" value={this.state.programDesc}  onChange={(event) => this.hadleUserInput(event)} />
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row">
                                <div className="col-12 p-0 mt-4">
                                    Responsabilities
                                    <button className="ProgramAddNew" onClick={(e) => this.addResponsabilitie(e)}>Add New</button>
                                </div>
                                <div className="col-12 addNewContainer mt-2 p-3">
                                    <div className="row addNewHeaders">
                                        <div className="col-7">
                                            Title
                                        </div>
                                        <div className="col-2 text-center">
                                            Mentee
                                        </div>
                                        <div className="col-2 text-center">
                                            Mentor
                                        </div>
                                    </div>
                                    {
                                        this.state.responsabilities.map((currentValue,index,array)=>{
                                            return(
                                            <div className="row py-2" key={index}>
                                                    <div className="col-7">
                                                    <input className="AddNewTitleInput" name="title" value={currentValue.title} onChange={(e) => this.changeResponsabilitie(e,index,currentValue)} ></input> 
                                                </div>
                                                <div className="col-2 text-center">
                                                    <input className="AddNewCheckbox" type="checkbox" name ="isMenteeOwner" checked={currentValue.isMenteeOwner} onChange={(e) => this.changeResponsabilitie(e,index,currentValue)} ></input> 
                                                </div>
                                                <div className="col-2 text-center">
                                                    <input className="AddNewCheckbox" type="checkbox" name="isMentorOwner"  checked={currentValue.isMentorOwner} onChange={(e) => this.changeResponsabilitie(e,index,currentValue)} ></input> 
                                                </div>
                                                <div className="col-1 text-center">
                                                    <button className="removeInput" onClick={(e) => this.onDelete(e,index,currentValue)}><i class="fas fa-times"></i></button>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-12 p-0 mt-4">
                                    Links
                                    <button className="ProgramAddNew" onClick={(e) => this.addLink(e)}>Add New</button>
                                </div>
                                <div className="col-12 addNewContainer mt-2 p-3">
                                    <div className="row addNewHeaders">
                                        <div className="col-4">
                                            Title
                                        </div>
                                        <div className="col-7 text-center">
                                            URL
                                        </div>
                                    </div>
                                    {
                                        this.state.links.map((currentValue,index,array)=>{
                                            return(
                                                <div className="row py-2" key={index}>
                                                    <div className="col-4">
                                                        <input className="AddNewTitleInput" name="title" value={currentValue.title} onChange={(e) => this.changeLink(e,index,currentValue)} ></input>
                                                    </div>
                                                    <div className="col-7 text-center">
                                                        <input className="AddNewTitleInput" name="address" value={currentValue.address} onChange={(e) => this.changeLink(e,index,currentValue)} ></input>
                                                    </div>
                                                    <div className="col-1 text-center">
                                                        <button className="removeInput" ><i class="fas fa-times"></i></button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                           
                        </div>
                    </div>

                    <div className="row float-right">
                        <input type="reset" value="Cancel" className="my-4"
                          onClick={
                              () => {
                                  Swal.fire({
                                      title: 'Are you sure you want to leave?',
                                      text: 'The information will be lost',
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: 'Yes'
                                  }).then((result) => {
                                    if (result.value) {
                                       window.open('/institutions/details/programs','_self');
                                      }
                                  })
                              }
                          }
                        />
                        <input type="submit" value="Add Program" className="my-4" 
                        onClick={ () => {
                            axios.post(direccion + "api/institution/program/"+ this.props.location.pathname.split("/").slice(1)[3],this.state,{
                                headers: {Authorization:"Bearer " + localStorage.getItem("UserSession") }
                            }).then((response) => {
                                localStorage.setItem("selectedInstitution",JSON.stringify(response.data.institutions[0]));
                                Swal
                                .fire({title: 'Success', icon: 'success', text: 'Program created'})
                                .then((result) => {
                                  if (result.value) {
                                    window.open("/institutions/details/programs","_self");
                                  }
                                });
                            } ).catch((error)=>{
                                Swal.fire({title: 'Error', text: error.response.data.stack[2], icon: 'error'});
                            }); 
                        }} 
                        />
                    </div>

                </form>
            </div>
        )
    }
}

export default AddProgram;