import React,{Component} from 'react';
import axios from "axios"; 
import readXlsxFile from 'read-excel-file';


// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;


class AddInterests extends Component{
    constructor(props){
        super(props);
        this.state = {
            professionalInterest:[{interests:'',category:''}],
            file:{},
            fileName:''
        }
      }
    
      hadleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState( { [name]:value } );
      }

      
      changeProfessionalInterest(e,index,currentValue){
        console.log(currentValue);
        this.state.professionalInterest[index][e.target.name] = e.target.value;
        this.setState({professionalInterest:this.state.professionalInterest});
        console.log(this.state.professionalInterest);
    }

    onChangeHandler=event=>{
        readXlsxFile(event.target.files[0]).then(data => {
                this.setState( { file:data } );
            });
        this.setState({fileName:event.target.files[0].name});
        
        
        }
    
    convert(e){
        e.preventDefault() 
        this.state.file.forEach((element,index,array) => 
                {
                    this.state.professionalInterest[index]={interests:element[0],category:element[1]}
                }
            );
            
        this.setState({professionalInterest:this.state.professionalInterest});
        console.log(this.state.professionalInterest);
    }

    
    addProfessionalInterest(e){
        e.preventDefault() ;
        this.setState({professionalInterest:[...this.state.professionalInterest,{interests:'',category:''}]});
        console.log(this.state.professionalInterest);
      }

      onDelete(e,index,currentValue) {
        e.preventDefault();
        this.state.professionalInterest.splice([index],1);
        this.setState({professionalInterest:this.state.professionalInterest});
      };

    render(){

        const current_id=this.props.location.pathname.split("/").slice(5)[0];
        const currentInstitutionName=this.props.location.pathname.split("/").slice(4)[0];
        console.log(current_id);

        return(
            <div className="container mt-4">
                <div className="row text-center">
                    <div className="col-12">
                        <h3>Add new Interests</h3>
                    </div>
                </div>
                <form className="text-left">
                    <div className="col-12 p-0 mt-4">
                        <button className="ProgramAddNew" onClick={(e) => this.addProfessionalInterest(e)}>Add New</button>
                    </div>
                    <div className="col-12 addNewContainer mt-2 p-3">
                        <div className="row addNewHeaders">
                            <div className="col-4">
                                Interests
                            </div>
                            <div className="col-8 text-center">
                                Category
                            </div>
                        </div>
                        {
                            this.state.professionalInterest.map((currentValue,index,array)=>{
                                return(
                                    <div className="row py-2" key={index}>
                                        <div className="col-4">
                                            <input className="AddNewTitleInput" name="interests" value={currentValue.interests} onChange={(e) => this.changeProfessionalInterest(e,index,currentValue)} ></input>
                                        </div>
                                        <div className="col-7 text-center">
                                            <input className="AddNewTitleInput" name ="category" value={currentValue.category} onChange={(e) => this.changeProfessionalInterest(e,index,currentValue)} ></input>
                                        </div>
                                        <div className="col-1 text-center">
                                            <button className="removeInput" onClick={(e) => this.onDelete(e,index,currentValue)}><i class="fas fa-times"></i></button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div class="my-5 inputArea">
                        <label>Upload Excel File and then click convert </label>
                        <div className="excelFile">
                            <input id="file" className="file m-3" type="file" name="file" onChange={this.onChangeHandler}/>
                            <span></span>
                            <label className="personalizedFile" for="file"><i class="far fa-file-excel"></i><br/>Choose a file</label>
                        </div>
                        
                        <button className="m-3 convert" onClick={(e) => this.convert(e)} > Convert  {" " + this.state.fileName} </button>
                    </div>

                    <div className="row float-right">
                        <input type="reset" value="Cancel" className="my-4"/>
                        <button type="button" value="Add Interests" className="my-4" 
                        onClick={ () => {
                            console.log("clicked");
                            console.log(localStorage.getItem("UserSession") )
                            axios.put(direccion + "api/institution/professionalInterest/"+ current_id,this.state,{
                                headers: {Authorization:"Bearer " + localStorage.getItem("UserSession") }
                            }).then((response) => {
                                console.log("goodanswer");
                                console.log(response);
                                alert("Interests added");
                                window.open("/institutions","_self");
                                this.setState({professionalInterest:[{interests:'',category:''}]});
                            } ).catch((error)=>{
                                console.log("badanswer");
                                console.log(error.response.data);
                            }); 
                        }} 
                        >Add Interests </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default AddInterests;
