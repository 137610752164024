  // Dependencies
  import React , {Component} from "react";

  // Assets
  import imgEx from '../../imgEx.jpg';

  class InstitutionsCards extends Component {

    render() {
      return(
        <div className="Institutions">
            <div className="row hoverCards">
              <div className="col-3">
                <img src={imgEx} className="CardImg"/>
              </div>
              <div className="col-4 InfoInstitutionCol">
                <div className="row text-left">
                  <div className="col-12  InfoHeader Raleway p-0">Name:</div>
                </div>
                <div className="row InstitutionsDetails">
                  <div className="InstitutionName Raleway text-center"><strong>{this.props.InstitutionName}</strong></div>
                </div>
              </div>
              <div className="col-3">
                <div className="row">
                  <div className="col-12  InfoHeader Raleway">Programs</div>
                </div>
                <div className="row InstitutionsDetails">
                  <div className="col-2 text-left "><strong>{this.props.programs.length}</strong></div>
                </div>
              </div>
              <div className="col-2">
                <div className="row">
                  <div className="col-12 InfoHeader Raleway">Status</div>
                </div>
                <div className="row InstitutionsDetails">
                  <div className="col-2 text-left pr-0">
                  {(() => {
                    switch (this.props.Status) {
                      case "Active":   return <i className="fas fa-check-circle"></i>;
                      case "Inactive": return <i className="fas fa-minus-circle"></i>;
                      case "Suspended":  return <i className="fas fa-times-circle"></i>;
                      default:  return <i className="fas fa-check-circle"></i>;
                    }
                  })()}
                  </div>
                  <div className="col-10 text-left p-0">{this.props.Status}</div>
                </div>
              </div>
            </div>
          <hr/>
        </div>

      )
    }
  }

  export default InstitutionsCards;
