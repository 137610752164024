// Dependecies
import React,{Component} from "react";

// Components
import Link from './Link';

class ProgramsLinks extends Component{
    render(){

        var Print = () =>{
            return this.props.links.map((v,i,a) =>  <Link address = {v.address} title = {v.title} />
            )
        }

        return(
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        <h5>Links</h5>
                    </div>
                </div>
                <hr/>
                <div className="container-fluid">
                    <div className="row">
                        <Print/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramsLinks;