import React from "react";
import { Bar } from "react-chartjs-2";

class BarCharts extends React.Component {
  state = {
    dataBar: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      datasets: [
        {
          label: "Mentors",
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)"
          ],
          borderWidth: 2,
          borderColor: [
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)",
            "rgba(59, 212, 192, 1)"
          ]
        }
      ]
    },
    barChartOptions: {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    }
  }


  render() {
    return (
        <div className="chart-card">
            <h4 className="chart-header">Mentors without match</h4>
            <Bar data={this.state.dataBar} options={this.state.barChartOptions} />
        </div>
    );
  }
}

export default BarCharts;