// Dependencies
import React, {Component} from "react";
import {NavLink, Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import Ratings from 'react-ratings-declarative';

// Components
import UserProfile from './UserProfile'
import UserPrograms from './UserPrograms'
import UserPerformance from './UserPerformance'
import SearchBar from '../Bars/SearchBar.js'

// Assets
import user2 from '../../user2.png';

class UserDetails extends Component {
constructor(props) {
    var userRole = JSON.parse(localStorage.getItem('User'));
    super(props);
    this.state = {
      currentUser: [JSON.parse(localStorage.getItem("selectedUser"))],
      userRole: [userRole.role]
    }
  }
  render() {
      
    const Print = () => {
      return (
        <div>
          <SearchBar
            SearchType="user"
            AddNewLink="/users/addnew"
            CardType="UserCards"
            hide=" d-none"
            cardViewCallback={this.selectedCard}/>
        </div>
      );
    }

    return (
        <div>
    <Print/>
      <div className="container-fluid">
        <BrowserRouter>
          <div className="row datesHeader">
            <div className="col-6 text-left my-3">
              <strong>Member since:
              </strong> {this.state.currentUser[0].createdOn.substring(0, 10)}
            </div>
            <div className="col-6 text-right  my-3 ">
              <strong>Last login on:
              </strong> {this.state.currentUser[0].lastLoginOn.substring(0, 10)}
            </div>
          </div>

          <div className="row userdetails pt-4 text-left">
            <div className="col-1 text-center m-auto imageContainer">
              <img src={user2} alt="User Image" className="userImage"/>
            </div>
            <div className="col-6">
              <div className="col-12 UserDetailName">User Information<hr/></div>
              <div className="row">
                <div className="col-6 m-auto">User name: {this.state.currentUser[0].userName}</div>
                <div className="col-6 m-auto">Phone:</div>
                <div className="col-6 m-auto">Email: {this.state.currentUser[0].email}</div>
                <div className="col-6 m-auto">City:</div>
                <div className="col-6 m-auto">Role: {this.state.currentUser[0].role}</div>
                <div className="col-6 m-auto">Country:</div>
              </div>
            </div>
            <div className="col-5 "></div>
          </div>

          <div className="SubMenu">
            <ul className="row">
              <NavLink to="/users/details/profile" className="col-6">
                <li>Profile Details</li>
              </NavLink>
              <NavLink to="/users/details/programs" className="col-6">
                <li>Programs</li>
              </NavLink>
              {/*<NavLink to="/user/performance"  className="col-6"><li>Performance Statics</li></NavLink>*/}
            </ul>
          </div>

          <Switch>
            <Route path="/users/details/profile" component={UserProfile}/>
            <Route path="/users/details/programs" component={UserPrograms}/>
            <Route path="/users/details/performance" component={UserPerformance}/>
            <Redirect from='/users/details' to='/users/details/profile'/>
          </Switch>
        </BrowserRouter>

      </div>
      </div>
    )
  }
}

export default UserDetails;