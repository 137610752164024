// Dependencies
import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Link,NavLink} from 'react-router-dom';

// CSS
import './SideBar.css';

// Assets
import logo2 from '../../logo2.png';


class SideBar extends Component {
  
  static propTypes= {
    items: PropTypes.array.isRequired
  }

  render() {

    const items = this.props.items; 

    return (
      <div>
          <nav id="sidebar" className="sidebarNav">
            <div className="sidebar-header">
              <img src={logo2}  alt="logo"/>
            </div>  
            <div className="sidebar-body">
                <div className="row ">
                <ul className="list-unstyled components">
                    {/* Dynamic menu list generator */}
                    {items && items.map((item,key) => 
                    <li key={key}>
                        <NavLink to={item.url} className="Raleway" activeClassName="NavActive"><i className={item.icon}></i>&nbsp;&nbsp;{item.BarTitle}</NavLink>
                    </li>)}
                    </ul>

                </div>
                  <div className="row bottom">
                      {/*<div className="col-6">
                          <button className="settings Raleway"  onClick={()=> {}}><i className="fas fa-cogs"></i>&nbsp;&nbsp;Settings</button>
                    </div>*/}
                      <div className="col-12 text-left">
                        <hr/>
                            <button className="logout ml-4" onClick={()=> {
                              window.open("/login","_self");
                              localStorage.clear();
                            }}><i className="fas fa-power-off" ></i>&nbsp;&nbsp;Logout </button>
                          
                        <hr/> 
                      </div>
                  </div>
              </div>
          </nav>
              

      </div>
    )

  }

}

export default SideBar;
