// Navigation Menu Data
export default[
    {
        BarTitle: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        url :'/dashboard'
    }/*,
    {
        BarTitle: 'Profile',
        icon: 'fas fa-university',
        url :'/institutions'
    },
    {
        BarTitle: 'Programs',
        icon: 'fas fa-tasks',
        url :'/programs'
    },
    {
        BarTitle: 'Mentors',
        icon: 'fas fa-user-tie',
        url :'/mentors'
    },
    {
        BarTitle: 'Mentees',
        icon: 'fas fa-user',
        url :'/mentees'
    },{
        BarTitle: 'Analytics',
        icon: 'far fa-chart-bar',
        url :'/analytics'
    }*/
]