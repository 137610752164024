// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom'

// Components
import Interests from './Interests';


class UserProfile extends Component{
    render(){
        return(
            <div className="container-fluid">
                <div className="row text-left">
                    <div className="col-12 mt-4">
                        <h5>Biography</h5><br/>
                        
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h5>Interests</h5><br/>
                        
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfile;