// Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// CSS
import "./SearchBar.css";

// Components
import SearchResults from "./SearchResults.js";
import ResultsBar from "../Bars/ResultsBar.js";
import UserCards from "../Users/UserCards";
import ProgramsCards from "../Programs/ProgramsCards";
import InstitutionsCards from "../Institutions/InstitutionsCards";

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      searchResults: [],
      activeIndex: 0,
      results: [],
      count: 0,
      selectedInstitution: [],
      show: "",
      expanded: "",
      hide: this.props.hide
    };
  }

  hadleUserInput(e) {
    const text = e.target.text;
    this.setState(text);
    this.state.show = "show";
    this.state.expanded = "true";
    if (this.props.SearchType != "program") {
      axios
        .get(
          direccion +
            "api/" +
            this.props.SearchType +
            "/search/" +
            e.target.value,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession")
            }
          }
        )
        .then(response => {
          console.log(response);
          this.state.searchResults =
            this.props.SearchType == "user"
              ? response.data.users
              : response.data.institutions;

          if (this.props.SearchType == "user") {
            this.setState({ results: this.state.searchResults });
            this.setState((this.state.count = this.state.results.lenght));
          } else if (this.props.SearchType == "institution") {
            this.setState({
              results: this.state.searchResults,
              count: this.state.searchResults.length
            });
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    } else {
      axios
        .post(
          direccion +
            "api/" +
            this.props.SearchType +
            "/search/" +
            e.target.value,
          {
            isProgramTemplate: true
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserSession")
            }
          }
        )
        .then(response => {
          console.log(response);
          this.state.searchResults = response.data.programs;
          this.setState({ results: this.state.searchResults });
          this.setState((this.state.count = this.state.results.lenght));
          this.setState({
            results: this.state.searchResults,
            count: this.state.searchResults.length
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  changeIndex(e, index, array) {
    localStorage.setItem("selectedInstitution", JSON.stringify(array[index]));
    localStorage.setItem("InstitutionsUsers", JSON.stringify(array[index].users));
    axios
      .get(direccion + "api/institution/program/" + array[index]._id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession")
        }
      })
      .then(response => {
        localStorage.setItem(
          "InstitutionsPrograms",
          JSON.stringify(response.data.programs)
        );
        window.open("/institutions/details/profile", "_self");
      })
      .catch(error => {
        console.log(error.response);
      });
    this.setState({ show: "", expanded: "false" });
  }

  changeIndexProgram(e, index, array) {
    localStorage.setItem("selectedProgram", JSON.stringify(array[index]));
    this.setState({ show: "", expanded: "false" });
    window.open("/programs/details/summary", "_self");
  }

  changeIndexUser(e, index, array) {
    localStorage.setItem("selectedUser", JSON.stringify(array[index]));
    this.setState({ show: "", expanded: "false" });
    window.open("/users/details/profile", "_self");
  }

  closeSearch(e){
    this.setState({ show: "", expanded: "false" });
  }

  render() {
    const Print = () => {
      if (this.props.SearchType == "institution") {
        return this.state.results.map((result, index, array) => (
          <div onClick={e => this.changeIndex(e, index, array)}>
            <InstitutionsCards
              InstitutionName={result.institutionName}
              InstitutionId={result._id}
              programs={result.programs}
            />
          </div>
        ));
      } else if (this.props.SearchType == "user") {
        return this.state.results.map((result, index, array) => (
          <div onClick={e => this.changeIndexUser(e, index, array)}>
            <UserCards
              email={result.email}
              UserName={result.userName}
              role={result.role}
            />
          </div>
        ));
      } else if (this.props.SearchType == "program") {
        return this.state.results.map((result, index, array) => (
          <div onClick={e => this.changeIndexProgram(e, index, array)}>
            <ProgramsCards
              name={result.name}
              institutionName={result.institutionName}
              startDate={result.startDate.substring(0, 10)}
              endDate={result.endDate.substring(0, 10)}
            />
          </div>
        ));
      }
    };

    return (
      <div>
        <nav className="navbar navbar-dark SearchBar">
          <div
            className={"resultsSection dropdown-menu " + this.state.show}
            aria-labelledby="dropdownResults"
          >
            <div className="text-left results-bar">
              <div className="row">
                <div className="col-11">
                  <span className="search-title">
                    {this.props.SearchType.charAt(0).toUpperCase() +
                      this.props.SearchType.slice(1) +
                      "s"}
                  </span>
                  <br/>
                  <span className="search-results">
                    Found {this.state.count} results
                  </span>
                </div>
                <div className="col-1 text-right">
                  <button onClick={e => this.closeSearch(e)} className="close-btn"><i class="fas fa-times"></i></button>
                </div>
              </div>
              <hr />
            </div>
            <div className="printSection">
              <Print />
            </div>
          </div>
          <div className="row h-100">
            <div className="col-md-3 col-6 text-left h-100">
              <div className="SearchForm">
                <input
                  className="SearchFormInput"
                  type="text"
                  placeholder={this.SearchType}
                  onChange={event => this.hadleUserInput(event)}
                  value={this.props.text}
                />
                <button className="SearchFormIcon">
                  <i className="fas fa-search"></i>
                </button>
                <div className={"dropdown " + this.state.show}></div>
              </div>
            </div>
            <div className="col-md-3 col-6 h-100 text-left"></div>
            <div className={"col-md-6 text-right " + this.state.hide}>
              <Link className="AddNew pr-2" to={this.props.AddNewLink}>
                <i className="fas fa-plus-circle"></i>
                <span className="AddText">&nbsp;&nbsp;Add New</span>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default SearchBar;
