// Dependencies
import React, {Component} from "react";
import axios from "axios"; 
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// Assets
import logo from '../../logo.png';
import auth from '../../auth'

// CSS
import './Login.css';

// Server URL
import serverURL from '../../serverURL'
var direccion =  serverURL ;

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:''
    }
  }

  hadleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState( { [name]:value } );
  }



  render() {
    return (

      <div className="Container loginBG">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className="row">
          <div className="col-1 col-md-4"></div>
          <div className="col-10 col-md-4">
            <div className="card login-card">
              <div className="card-body">
                <div className="card-title gray">Login</div>
                  <div className="container">
                    <form>
                      <div className="row">
                        <input type="email" id="inputEmail" placeholder="E-mail" className="form-input" name="email" value={this.state.email} onChange={(event) => this.hadleUserInput(event)} />
                      </div>
                      <div className="row">
                        <input type="password" id="password" placeholder="Password" className="form-input" name="password" value={this.state.password}  onChange = {(event) => this.hadleUserInput(event)}/>
                      </div>
                      <div className="row form-row">
                        <div className="col-6 text-left">
                          <label className="switch">
                            <input type="checkbox"/>
                            <span className="slider round"></span>
                          </label>
                          <span className="grayFont">Remember me</span>
                        </div>
                        <div className="col-6 text-right">
                          <a href="">Forgot password?</a>
                        </div>
                      </div>

                      <button 
                        onClick={ (e) => {
                          e.preventDefault();
                          axios.post("http://ec2-34-220-206-184.us-west-2.compute.amazonaws.com:8080/login",{},{
                            auth: {
                                username:this.state.email,
                                password:this.state.password
                              }
                          })
                          .then((response) => {
                              console.log(response);
                              localStorage.setItem("UserSession",JSON.stringify(response.data.token).replace(/"/g,''));
                              localStorage.setItem("User",JSON.stringify(response));
                              localStorage.setItem("UserId",JSON.stringify(response));

                              axios.post("http://api-itg.mentormeconnect.com/legacy/getAdminMetrics",{userId:response.data.id}
                              )
                              .then((response) => {
                                  console.log(response);
                                  localStorage.setItem("matchesData",JSON.stringify(response.data.legacy.matches));
                                  localStorage.setItem("lastActivities",JSON.stringify(response.data.legacy.lastActivities));
                                  localStorage.setItem("programsCount",JSON.stringify(response.data.legacy.programs.count));
                                  localStorage.setItem("programsGoals",JSON.stringify(response.data.legacy.programs.goals));
                                  window.open('/dashboard','_self');
                              } )
                                .catch((error)=> {
                                      Swal.fire({
                                        title: 'Error',
                                        icon: 'error',
                                        text: error
                                      }).then((result) => {
                          
                                      })
                                      console.log(error.response);;
                                    }); 
                          } )
                            .catch((error)=> {
                                  Swal.fire({
                                    title: 'Error',
                                    icon: 'error',
                                    text: error
                                  }).then((result) => {

                                  })
                                console.log(error.response.data);;
                                }); 
                          
                            }}  

                        type="submit" className="btn btn-primary loginButtons">
                          Log In
                      </button>

                    </form>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-1 col-md-4"></div>
        </div>
      </div>
    )

  }

}

export default Login;
