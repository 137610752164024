// Dependecies
import React,{Component} from "react";


class Document extends Component{
    render(){
        return(
            <div className="text-left col-4 document my-2">
                <div className="row">
                    <div className="col-2 text-right p-0 pt-2"><i class="far fa-file-alt"></i></div>
                    <div className="col-10 p-0">
                        <div className="row">
                            <div className="col-12 DocumentTitle">File name lorem ipsum.pdf</div>
                            <div className="col-12">September 7 2016   00:00 AM</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Document;