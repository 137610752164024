// Dependencies
import React, {Component} from "react";
import {NavLink, Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import axios from "axios";

// Components
import InstitutionsHeaderCards from './InstitutionsHeaderCards';
import InstitutionProfile from './InstitutionProfile';
import InstitutionalPrograms from './InstitutionalPrograms';
import InstitutionalUsers from './InstitutionalUsers';
import AddNewAdmin from './AddNewAdmin';
import AddInterests from './AddInterests';
import SearchBar from '../Bars/SearchBar.js'
import InstitutionsCards from './InstitutionsCards.js'
import AddUsers from "./AddUsers";

// Server URL
import serverURL from '../../serverURL'
var direccion = serverURL;

class InstitutionDetails extends Component {

  constructor(props) {

    var userRole = JSON.parse(localStorage.getItem('User'));
    super(props);
    this.state = {
      currentInstitution: [JSON.parse(localStorage.getItem("selectedInstitution"))],
      userRole: [userRole.role],
      institutions: JSON.parse(localStorage.getItem('Institutions'))
    }
  }

  selectedCard(institution) {
    localStorage.setItem("selectedInstitution", JSON.stringify(institution));
    localStorage.setItem("InstitutionsUsers", JSON.stringify(institution.users));
    axios
      .get(direccion + "api/institution/program/" + institution._id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("UserSession")
      }
    })
      .then((response) => {
        localStorage.setItem("InstitutionsPrograms", JSON.stringify(response.data.programs));
        window
          .location
          .reload();
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  render() {

    const Print = () => {
      if (this.state.userRole == "sysadmin") {
        return (
          <div>
            <SearchBar
              AddNewLink="/institutions/addnew"
              SearchType="institution"
              cardViewCallback={this.selectedCard}/>
          </div>

        );
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-5">
                <button
                  className="btn btn-outline-primary btn-sm p-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1">Show institutions</button>
              </div>
            </div>
            {this
              .state
              .institutions
              .map((result, index, array) => <div
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="collapse multi-collapse">
                <InstitutionsCards
                  InstitutionName={result.institutionName}
                  programs={result.programs}/>
              </div>)}
          </div>
        )
      }
    }

    return (
      <div>
        <Print/>
        <BrowserRouter>
          <InstitutionsHeaderCards
            users={this.state.currentInstitution[0].users}
            programs={this.state.currentInstitution[0].programs}
            status={this.state.currentInstitution[0].status}
            />

          <div className="SubMenu">
            <ul className="row">
              <NavLink to="/institutions/details/profile" className="col-4">
                <li>Institution Profile</li>
              </NavLink>
              <NavLink to="/institutions/details/programs" className="col-4">
                <li>Institutional Programs</li>
              </NavLink>
              <NavLink to="/institutions/details/users" className="col-4">
                <li>Associated Users</li>
              </NavLink>
            </ul>
          </div>

          <Switch>
            <Route
              path="/institutions/details/profile"
              render={(props) => <InstitutionProfile
              {...props}
              _id={this.state.currentInstitution[0]._id}
              institutionName={this.state.currentInstitution[0].institutionName}
              status={this.state.currentInstitution[0].status}
              admins={this.state.currentInstitution[0].admins}
              contact={this.state.currentInstitution[0].contact}
              createdOn={this.state.currentInstitution[0].createdOn}
              city={this.state.currentInstitution[0].city}
              country={this.state.currentInstitution[0].country}
              description={this.state.currentInstitution[0].description}
              phone={this.state.currentInstitution[0].phone}
              email={this.state.currentInstitution[0].email}
              programs={this.state.currentInstitution[0].programs}
              state={this.state.currentInstitution[0].state}
              streetAddress={this.state.currentInstitution[0].streetAddress}
              users={this.state.currentInstitution[0].users}
              zip={this.state.currentInstitution[0].zip}
              webPageUrl={this.state.currentInstitution[0].webPageUrl}
              professionalInterest={this.state.currentInstitution[0].professionalInterest}/>}/>
            <Route
              path="/institutions/details/programs"
              render={(props) => <InstitutionalPrograms
              {...props}
              institutionName={this.state.currentInstitution[0].institutionName}
              _id={this.state.currentInstitution[0]._id}
              programs={this.state.currentInstitution[0].programs}/>}/>
            <Route
              path="/institutions/details/users"
              render={(props) => <InstitutionalUsers {...props} users={this.state.currentInstitution[0].users}/>}/>
            <Route path="/institutions/details/newadmin" component={AddNewAdmin}></Route>
            <Route path="/institutions/details/newinterests" component={AddInterests}></Route>
            <Route path="/institutions/details/newusers" component={AddUsers}></Route>
            <Redirect from='/institutions' to='/institutions/details/profile'/>
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default InstitutionDetails;
