// Dependecies
import React,{Component} from "react";

class Tasks extends Component{
    
    printStatus(status){
        if(status==true){
            return (<i class="fas fa-check-circle"></i>)
        }else{
            return (<span>-</span>);
        }
    }
    render(){
        return(
                <div className="col-12">
                    <div className="row ">
                        <div className="col-6 text-left">{this.props.description}</div>
                        <div className="col-2 text-center">{this.props.durationInDays + " days"}</div>
                        <div className="col-2 text-center">{this.printStatus(this.props.menteeAssignment)}</div>
                        <div className="col-2 text-center">{this.printStatus(this.props.mentorAssignment)}</div>
                    </div>
                    <hr/>
                </div>
        )
    }
}

export default Tasks;