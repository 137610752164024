// Dependecies
import React,{Component} from "react";

class InstitutionsHeaderCards extends Component{
    constructor(props){
    
        super(props);
        this.state = {
            status:this.props.status,
            statusCheckbox:true
        }
      }

      validateStatus(){
          if(this.state.status == "active"){
            this.setState({statusCheckbox:true});
          }else{
            this.setState({statusCheckbox:false});
          }
      }


    render(){
        
        const Print = () => {
            if(this.props.status=="active"){
                return (
                    <div className="col-4 text-left p-0 StatusHeader">
                        <i className="far fa-check-circle"></i><span className="text">{this.props.status}</span>
                    </div>
                );
            }else{
                return (
                    <div className="col-4 text-left p-0 StatusHeader">
                        <i className="far fa-check-circle"></i><span className="text">{this.props.status}</span>
                    </div>
                );
            }
            
        }
        return(
            <div className="row InstitutionsHeaderCards">
                <div className="col-4 border-right">
                    <div className="col-12">
                        <i className="fas fa-university"></i>
                    </div>
                    <div className="col-12">
                        {this.props.programs.length}
                    </div>
                    <div className="col-12 subtitle">
                        Programs
                    </div>
                </div>
                <div className="col-4 border-right">
                    <div className="col-12">
                        <i className="fas fa-user"></i>
                    </div>
                    <div className="col-12">
                        {this.props.users.length}
                    </div>
                    <div className="col-12 subtitle">
                        Users
                    </div>
                </div>
                <div className="col-4">
                    <div className="col-12 text-left mt-1">
                        <h4>Institution Status</h4>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row ">
                            <Print/>
                            <div className="col-8 text-left mt-2">
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.statusCheckbox}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InstitutionsHeaderCards;
