// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// Components
import Alerts from './Alerts';

class Dropdown extends Component{
    render(){
        return(
            <div className="dropdown-menu">
                <div className="dropdown-header">You have {this.props.AlertNumber} new requests:</div>
                <div className="dropdown-divider"></div>
                    <div className="dropdown-alerts">
                      <Alerts
                       AlertDate="11/05/11"
                       AlertTime="10:23"
                       AlertName="User Name"
                       AlertMessage="10 days without activity" 
                      />
                      <Alerts
                         AlertDate="11/05/11"
                         AlertTime="10:23"
                         AlertName="User Name"
                         AlertMessage="12 days without match"  
                      />
                      <Alerts
                         AlertDate="11/05/11"
                         AlertTime="10:23"
                         AlertName="User Name"
                         AlertMessage="12 days without match" 
                      />
                      <Alerts
                        AlertDate="11/05/11"
                        AlertTime="10:23"
                        AlertName="User Name"
                        AlertMessage="12 days without match" 
                      />
                    </div>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item view-all" to="/notifications">View all requests</Link>
            </div>
        )
    }
}

export default Dropdown;