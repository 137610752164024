// Dependencies
import React, {Component} from 'react';
import axios from "axios";
import Swal from 'sweetalert2'


// Server URL
import serverURL from '../../serverURL'
var direccion = serverURL;

class AddInstitution extends Component {

  constructor(props) {
    super(props);
    this.state = {
      institutionName: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      webPageUrl: ''
    }
  }

  hadleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  render() {
    return (
      <div className="container my-3">
        <form action="">
          <div className="row text-left">
            <div className="col-2">
              <label htmlFor="file-upload" class="custom-file-upload">
                <i class="fas fa-camera"></i><br/>Upload an image
              </label>
              <input
                id="file-upload"
                type="file"
                name="image"
                value={this.state.image}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-10 mt-4">
              Institution
              <input
                type="text"
                className="mt-2"
                name="institutionName"
                value={this.state.institutionName}
                onChange={(event) => this.hadleUserInput(event)}
                required/>
            </div>
            <div className="col-6 mt-4">
              Requestor's Name
              <input
                type="text"
                className="mt-2"
                name="contactFirstName"
                value={this.state.contactFirstName}
                onChange={(event) => this.hadleUserInput(event)}
                required/>
            </div>
            <div className="col-6 mt-4">
              Requestor's Last Name
              <input
                type="text"
                className="mt-2"
                name="contactLastName"
                value={this.state.contactLastName}
                onChange={(event) => this.hadleUserInput(event)}
                required/>
            </div>
            <div className="col-6 mt-4">
              Requestor's Phone
              <input
                type="tel"
                className="mt-2"
                name="contactPhone"
                value={this.state.contactPhone}
                onChange={(event) => this.hadleUserInput(event)}
                required/>
            </div>
            <div className="col-6 mt-4">
              Requestor's Email
              <input
                type="email"
                className="mt-2"
                name="contactEmail"
                value={this.state.contactEmail}
                onChange={(event) => this.hadleUserInput(event)}
                required/>
            </div>
            <div className="col-12 mt-4">
              Street Address
              <input
                type="text"
                className="mt-2"
                name="streetAddress"
                value={this.state.streetAddress}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-3 mt-4">
              Country
              <input
                type="text"
                className="mt-2"
                name="country"
                value={this.state.country}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-3 mt-4">
              State
              <input
                type="text"
                className="mt-2"
                name="state"
                value={this.state.state}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-3 mt-4">
              City
              <input
                type="text"
                className="mt-2"
                name="city"
                value={this.state.city}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-3 mt-4">
              Zip / Postal code
              <input
                type="number"
                className="mt-2"
                name="zip"
                value={this.state.zip}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-12 mt-4">
              Institution Phone Number
              <input
                type="tel"
                className="mt-2"
                name="phone"
                value={this.state.phone}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-12 mt-4">
              Institution Email
              <input
                type="email"
                className="mt-2"
                name="email"
                value={this.state.email}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-12 mt-4">
              Website URL
              <input
                type="url"
                className="mt-2"
                name="webPageUrl"
                value={this.state.webPageUrl}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
            <div className="col-12 mt-4">
              Short description about the institution
              <input
                type="text"
                className="mt-2"
                name="description"
                value={this.state.description}
                onChange={(event) => this.hadleUserInput(event)}/>
            </div>
          </div>

          <div className="row float-right">
            <input type="reset" value="Cancel" className="my-4"/>
            <input
              id="AddInstitution"
              type="submit"
              value="Add Institution"
              className="my-4"
              onClick={(e) => {
                e.preventDefault();
              axios
                .post(direccion + "api/institution", this.state, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("UserSession")
                }
              })
                .then((response) => {
                  Swal
                    .fire({title: 'Success', icon: 'success', text: 'Institution created'})
                    .then((result) => {
                      if (result.value) {
                        window.open("/institutions", "_self")
                      }
                    });
                })
                .catch((error) => {
                  Swal.fire({title: 'Error', text: error.response.data.stack[1], icon: 'error'});
                });
            }}/>
          </div>

        </form>
      </div>
    )
  }
}

export default AddInstitution;