// Dependencies
import React,{Component} from 'react';

class Interests extends Component{
    
    render(){
        return(
            <div className="InterestCards">
                {this.props.interests}
            </div>
        )
    }

}

export default Interests;