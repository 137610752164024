// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// Assets
import ImgEx from '../../user.png';



class Alerts extends Component{
    render(){
        return(
            <div className="AlertCard">
                <Link to="">
                <div className="">
                    <div className="row AlertDate">
                        <div className="col mb-2">{this.props.AlertDate},&nbsp;{this.props.AlertTime}</div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <img className="ImgAlerts" src={ImgEx}/>
                        </div>
                        <div className="col-10 AlertMessage">
                            <span className="AlertName">{this.props.AlertName}&nbsp;</span>{this.props.AlertMessage}
                        </div>
                    </div>
                </div>
                </Link>
            </div>
        )
    }
}

export default Alerts;
