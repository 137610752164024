import React from "react";
import { Line } from "react-chartjs-2";

class LineCharts extends React.Component {
    state = {
      dataLine: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Program 1",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(47,169,153, .3)",
            borderColor: "rgb(47,169,153)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgb(47,169,153)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgba(47,169,153, 1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [28, 48, 40, 19, 86, 27, 90]
          },
          {
            label: "Program 2",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(60,40,101, .3)",
            borderColor: "rgb(60,40,101)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgb(60,40,101)",
            pointBackgroundColor: "rgb(0, 0, 0)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(255, 255, 255)",
            pointHoverBorderColor: "rgba(60,40,101,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
          }
        ]
      }
    };

    render() {
        return (
            <div className="chart-card">
              <h4 className="chart-header">Matches</h4>
              <Line data={this.state.dataLine} options={{ responsive: true }} />
            </div>
        );
      }
    }
    
    export default LineCharts;