// Dependencies
import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// Assets
import ImgEx from '../../user.png';

// Components




class NotificationCards extends Component{
    
    render(){
        return(
            <div className="NotificationCards">
                <Link>
                    <div className="">
                        <div className="row AlertDate">
                            <div className="col-2">
                                <img className="ImgAlerts" src={ImgEx}/>
                            </div>
                            <div className="col-10 mb-2 text-left">
                                <div className="row">
                                    <div className="col-12 AlertMessage mt-2    ">
                                        <span className="AlertName">{this.props.AlertName}&nbsp;</span>{this.props.AlertMessage}
                                    </div>
                                    <div className="col-12 mt-2">
                                        {this.props.AlertDate},&nbsp;{this.props.AlertTime}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </Link>
            </div>
        )
    }

}

export default NotificationCards;