import React, {Component} from "react";

class ResultsBar extends Component {

  render() {
    return (
      <div className="text-left results-bar">
        <span className="search-title">{this.props.SearchTitle}</span>
        <br/>
        <span className="search-results">Found {this.props.SearchResults} results</span>
        <hr/>
      </div>

    )

  }

}

export default ResultsBar;
