// Dependencies
import React, {Component} from 'react';
import axios from "axios";

// Components
import SearchBar from '../Bars/SearchBar.js'
import ProgramsCards from '../Programs/ProgramsCards'

// CSS
import './Programs.css';
import ProgramDetails from './ProgramDetails.js';

// Server URL
import serverURL from "../../serverURL";
var direccion = serverURL;

class Programs extends Component {

  constructor(props) {
    var userRole = JSON.parse(localStorage.getItem('User'));
    super(props);
    this.state = {
      currentProgram: [],
      programs: JSON.parse(localStorage.getItem('Programs')),
      userRole: [userRole.role]
    }
  }

  selectedCard(program) {
    if(this.state.userRole=="sysadmin"){
    localStorage.setItem("selectedProgram", JSON.stringify(program));
  }else{
    axios
    .post(
      direccion +
        "api/program" +
        "/search/" +
       program.name,
      {
        isProgramTemplate: true
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("UserSession")
        }
      }
    )
    .then(response => {
        localStorage.setItem("selectedProgram", JSON.stringify(response.data.programs[0]));
        window.open("/programs/details/summary", "_self");
      })
    .catch(error => {
      console.log(error.response);
    });
   
  }
   
    this.setState({show: "", expanded: "false"})
  }

  render() {
    const Print = () => {
      if (this.state.userRole == "sysadmin") {
        return (
        <SearchBar
          AddNewLink="/programs/addnew"
          SearchType="program"
          cardViewCallback={this.selectedCard}
          hide= "d-none" 
          />);
      } else {
        return (
          <div className="">
            <div className="navbar navbar-dark SearchBar text-left row">
              <div className="col-7">
                <button
                  className="btn btn-outline-primary btn-sm p-0"
                  data-toggle="collapse"
                  data-target="#multiCollapseExample1"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1">Show programs</button>
              </div>
            </div>
            {this
              .state
              .programs
              .map((result, index, array) => <div
                onClick={(e) => this.selectedCard(array[index])}
                id="multiCollapseExample1"
                className="show multi-collapse">
                <ProgramsCards
                  name={result.name}
                  institutionName={result.institutionName}
                  startDate={result
                  .startDate
                  .substring(0, 10)}
                  endDate={result
                  .endDate
                  .substring(0, 10)}/>
              </div>)}
          </div>
        )
      }
    }

    return (
      <div className="Programs">
        <Print/>

      </div>
    )
  }

}

export default Programs;