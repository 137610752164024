// Dependencies
import React,{Component} from "react";
import {NavLink, Switch, Route,BrowserRouter,Redirect} from 'react-router-dom';

// Components
import ProgramsSummary from './ProgramsSummary'
import ProgramsGoalsTasks from './ProgramsGoalsTasks'
import ProgramsLinks from './ProgramsLinks'
import ProgramsMentors from './ProgramsMentors'
import ProgramsMentees from './ProgramsMentees'

class ProgramDetails extends Component{

    constructor(props) {

        var userRole = JSON.parse(localStorage.getItem('User'));
        super(props);
        this.state = {
          currentProgram: [JSON.parse(localStorage.getItem("selectedProgram"))],
          userRole: [userRole.role]
        }
      }

      selectedCard(program){
        localStorage.setItem("selectedProgram", JSON.stringify(program));
        this.setState({show: "", expanded: "false"});
        window
          .location
          .reload();
      }

    render(){

        return(
            <div>
                <BrowserRouter>
                    <div className="SubMenu">
                        <ul className="row">
                            <NavLink to="/programs/details/summary"  className="col-3"><li>Program summary</li></NavLink>
                            <NavLink to="/programs/details/goalstasks"  className="col-3"><li>Goals and Tasks</li></NavLink>
                            <NavLink to="/programs/details/links"  className="col-2"><li>Links</li></NavLink>
                            <NavLink to="/programs/details/mentors"  className="col-2"><li>Associated Mentees</li></NavLink>
                            <NavLink to="/programs/details/mentees"  className="col-2"><li>Associated Mentees</li></NavLink>
                        </ul>
                    </div>

                    <Switch>
                        <Route exact path="/programs/details/summary" render={(props) => <ProgramsSummary {...props} name={this.state.currentProgram[0].name} startDate={this.state.currentProgram[0].startDate} endDate={this.state.currentProgram[0].endDate} institutionName={this.state.currentProgram[0].institutionName} description={this.state.currentProgram[0].description} responsabilities={this.state.currentProgram[0].responsabilities} /> }/>
                        <Route path="/programs/details/goalstasks" render={(props) => <ProgramsGoalsTasks {...props} goals={this.state.currentProgram[0].goals} /> }/>
                        <Route path="/programs/details/links" render={(props) => <ProgramsLinks {...props} links={this.state.currentProgram[0].links} /> }/>
                        <Route path="/programs/details/mentors" render={(props) => <ProgramsMentors {...props} />  }/>
                        <Route path="/programs/details/mentees" render={(props) => <ProgramsMentors {...props} />  }/>
                        <Redirect from="/programs" exact to="/programs/details/summary" />
                    </Switch>
                </BrowserRouter>

            </div>
        )
    }
}

export default ProgramDetails;
